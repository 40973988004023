import { JSONObject, Mapper } from "yummypets-js-core";
import { Survey } from "./survey.model";

export class SurveyMapper implements Mapper<JSONObject, Survey> {
    
    mapFromEntity(json: JSONObject): Survey {

        const survey = <Survey>{} 

        try {
    
            survey.id = json.id
            survey.title = json.title || ''
            survey.description = json.description || ''
            survey.shortDescription = json.short_description || ''
            survey.image = json.image
            survey.petTypes = json.pet_types
            survey.surveyUrl = json.survey_url || ''
            survey.rewardsTxt = json.rewards_txt || ''
            survey.open = json.open || false
            survey.respondent = json.respondent || false
            survey.answers = json.answers || 0
            survey.category = json.category
            survey.country = json.country
            survey.lang = json.lang
            survey.dateStart = json.date_start
            survey.dateEnd = json.date_end
            survey.date = json.date
            survey.fetchUrl = `/surveys/${survey.id}`
            survey.resultsAvailable = json.results_available || false;
            survey.drawFileUrl = json.draw_file_url

        } catch (error) {

            console.warn(`Error on ${this.constructor.name}'s mapFromEntity function : `, error)
        }
      
        return survey
    }
}
      